import { useEffect } from 'react';
import { useGetSlotDetailQuery } from 'common/api/proctor';
import { formattedDateTime, formattedTime } from 'common/utils/DateTimeUtils';
import { useAppDispatch } from 'app/hooks';
import { updateBreadcrumb } from 'common/slice/breadcrumbsSlice';
import { setActiveAttendee } from 'common/slice/activeAttendeeSlice';
import Header from 'common/components/Header';
import Loader from 'common/components/Loader';
import Session from './Session';
import ActionModal from 'common/components/ActionModal';
import { startSession } from 'common/slice/sessionListSlice';
import { Mixpanel } from '../../Mixpanel';

const SlotDetail = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = +urlParams.get('event_id');
  const slot_id = +urlParams.get('slot_id');

  const { data, isSuccess, isLoading } = useGetSlotDetailQuery({
    event_id,
    slot_id,
  });

  useEffect(() => {
    if (data) {
      Mixpanel.track('proview_console_tile_view_page_opened');
        const url = `/slot?event_id=${event_id}&slot_id=${slot_id}`;
        const label = `${formattedDateTime(data?.slot_starts_at)} | ${formattedTime(
          data?.slot_starts_at,
        )} - ${formattedTime(data?.slot_ends_at)}`;
        const payload = [{
          label,
          url,
        }];
        dispatch(updateBreadcrumb(payload));
      const defaultAttendee = data.attendees[0];
      if (defaultAttendee) {
        dispatch(setActiveAttendee({ ...defaultAttendee, isDetailView: false }));
      }
      dispatch(startSession(data.participant_group_id));
    }
  }, [data]);
  
  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <div className="flex flex-col w-screen h-screen">
          <Header />
          <Session attendees={data?.attendees} participant_group_id={data?.participant_group_id} />
          <ActionModal />
        </div>
      )}
    </>
  );
};

export default SlotDetail;
